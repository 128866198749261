import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";

//import images
// import features1 from "../assets/images/features/img-1.png";
// import features2 from "../assets/images/features/img-2.png";
import leaveImage from "../assets/images/features/leave-application.jpg"
import employeeImage from "../assets/images/features/employee.jpg"
import adminImage from "../assets/images/features/admin.jpg"
import timesheetImage from "../assets/images/features/timesheet.jpg"
import projectImage from "../assets/images/features/project.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft, faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import "./Features.css"

const cardData = [
  {
    cardNo: 1,
    image: leaveImage,
    title: "Leave Management",
    content: "We offer a leave tracker that includes custom week offs, various leave types, and the ability to customize the number of leave days. Employees can apply for leave, and managers can approve or reject leave requests."
  },
  {
    cardNo: 2,
    image: employeeImage,
    title: "Employee Management",
    content: "Effortlessly manage and update your employee information, keeping your records current with ease. Seamlessly maintain an organized, up- to - date database for efficient employee management."
  },
  {
    cardNo: 3,
    image: adminImage,
    title: "Administrative Process",
    content: "Empower your team with group-based role management and customizable feature assignments to unleash their full potential within your organization."
  },
  {
    cardNo: 4,
    image: timesheetImage,
    title: "Timesheet Management",
    content: "Track employee performance, monitor progress, and gauge efficiency with ease. Generate insightful reports to evaluate and enhance employee performance effectively."
  },
  {
    cardNo: 5,
    image: projectImage,
    title: "Project Management",
    content: "Transform your project workflow with ease! Our platform streamlines task assignment and slashes completion time, packed with features to help you plan and execute projects perfectly, every time."
  },
]
const Features = () => {
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(3);
  const [showCards, setShowCard] = useState([])

  useEffect(() => {
    setShowCard(cardData.filter((data) => data.cardNo >= start && data.cardNo <= end))
    // 
  }, [start, end])

  const handlBackward = () => {
    if (start > 0) {
      setStart(start - 1);
      setEnd(end - 1);
    }
  }
  const handlForward = () => {
    if (end < 5) {
      setEnd(end + 1);
      setStart(start + 1);
    }
  }

  return (
    <React.Fragment>
      <section className="section bg-light" id="features">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="heading-box text-center">
                <h3 className="heading-title">
                  {/* <span className="fw-normal">The Features that </span> we
                  present */}
                  FEATURES WE OFFERED
                </h3>
                {/* <p className="heading-desc text-muted mt-3">
                  We craft digital, graphic and dimensional thinking, to create
                  category leading brand experiences that have meaning and add a
                  value for our clients.
                </p> */}
                <p className="heading-desc text-muted mt-3">
                  <FontAwesomeIcon icon={faQuoteLeft} color="#12a894" />
                  &nbsp;&nbsp;&nbsp;We offer a wide range of features to effectively manage your organization.
                  Our solutions enable you to increase production and revenue, while reducing workload and operational expenses.
                  Our self-service portals empower your employees to apply for leaves, submit work reports, and more. &nbsp;&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faQuoteRight} color="#12a894" />
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mt-5 d-flex align-items-center justify-content-center">
            <Col md="1">
              {start !== 1 &&
                <FontAwesomeIcon
                  icon={faChevronCircleLeft}
                  style={{
                    height: "70px",
                    cursor: "pointer"
                  }}
                  color="#12a894"
                  onClick={handlBackward}
                />
              }
            </Col>
            {showCards && showCards.map((item) => (
              <Col md='3' >
                <Card className="card-style" style={{ minHeight: "450px" }}>
                  <img
                    src={item.image}
                    alt=""
                    style={{
                      display: "block",
                      width: "auto",
                    }}
                  />
                  <span className="heading-title text-uppercase fw-bold text-center py-3 text-primary">
                    {item.title}
                  </span>
                  <div className="">
                    <p className="px-2" style={{ textAlign: "justify" }}>
                      {item.content}
                    </p>
                  </div>
                </Card>
              </Col>
            ))}
            <Col md="1">
              {
                end !== 5 &&
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  style={{
                    height: "70px",
                    cursor: "pointer"
                  }}
                  color="#12a894"
                  onClick={handlForward}
                />
              }
            </Col>
          </Row>
          {/* <Row className="mt-5 pt-4 align-items-center">
            <Col lg={5}>
              <div className="mt-4">
                <div className="mt-4">
                  <h3>
                    <span className="fw-normal">We love make things </span>
                    amazing and simple
                  </h3>
                </div>
                <div className="mt-4 pt-3">
                  <div className="d-flex">
                    <div className="features-count">
                      <h5 className="f-14 text-primary">01</h5>
                    </div>

                    <div className="flex-1 ps-3">
                      <h5 className="f-18 mt-1">Marketing Performance</h5>
                      <p className="text-muted mt-2">
                        Separated they live in Bookmarksgrove right at the coast
                        the Semantics, a large language ocean. A small river
                        name Duden flows by regelialia.
                      </p>
                    </div>
                  </div>

                  <div className="d-flex mt-3">
                    <div className="features-count">
                      <h5 className="f-14 text-primary">02</h5>
                    </div>

                    <div className="flex-1 ps-3">
                      <h5 className="f-18 mt-1">Marketing business</h5>
                      <p className="text-muted mt-2 mb-0">
                        Separated they live in Bookmarksgrove right at the coast
                        of the Semantics, a large language ocean publishing web
                        page editors now.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} className="offset-lg-1">
              <div className="mt-4 p-5">
                <img src={features1} className="img-fluid" alt="" />
              </div>
            </Col>
          </Row> */}

          {/* <Row className="mt-5 pt-4 align-items-center">
            <Col lg={6}>
              <div className="mt-4 p-5">
                <img src={features2} className="img-fluid" alt="" />
              </div>
            </Col>

            <Col lg={5} className="offset-lg-1">
              <div className="mt-4">
                <h3>
                  <span className="fw-normal">Creative solutions to </span>{" "}
                  expand your business!
                </h3>
              </div>

              <div className="mt-4 pt-3">
                <div className="d-flex">
                  <div className="features-icon">
                    <i className="pe-7s-display2 text-primary"></i>
                  </div>
                  <div className="flex-1 ps-3">
                    <h5 className="f-18 mt-1">
                      We put a lot of effort in design.
                    </h5>
                    <p className="text-muted mt-2">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content. Moltin gives
                      platform web sites still in their infancy.
                    </p>
                  </div>
                </div>

                <div className="d-flex mt-3">
                  <div className="features-icon">
                    <i className="pe-7s-date text-primary"></i>
                  </div>
                  <div className="flex-1 ps-3">
                    <h5 className="f-18 mt-1">Submit Your Orgnization.</h5>
                    <p className="text-muted mt-2">
                      Credibly brand standards compliant on users without
                      extensible services. Anibh euismod tincidunt laoreet Ipsum
                      combined with a passage.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row> */}
        </Container>
      </section>
    </React.Fragment >
  );
};

export default Features;
