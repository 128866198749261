import { CLEAR_MESSAGE, NOTIFICATION_MESSAGE } from "./constants";
import agent from "../Service/agent"

export const notifyMessage = (message) => ({
    type: NOTIFICATION_MESSAGE,
    payload: { message }
})

export const clearMessage = () => ({
    type: CLEAR_MESSAGE,
})

export const register = (data) => (dispatch) => {
    const dataToSend = {
        ...data,
        isSelfRegister: true,
    }
    return agent.Organization.register(dataToSend)
        .then(() => {
            const msg = {
                type: "result",
                content: "Your got free trial of vizo. Further information you can refer your registered email."
            }
            dispatch(notifyMessage(msg))
        })
        .catch((err) => {
            console.log("err: ", err);
            if (err && err.response) {
                const { text } = err.response;
                const msg = {
                    type: "error",
                    content: text
                }
                console.log("text: ", text)
                console.log("err.response: ", err.response)
                dispatch(notifyMessage(msg))
            }
            else {
                const msg = {
                    type: "error",
                    content: "Something went wrong. Please try again later."
                }
                dispatch(notifyMessage(msg))
            }
        })
}