import { useEffect } from 'react';

const useGoogleReCaptcha = (siteKey) => {
    useEffect(() => {
        const loadReCaptcha = () => {
            if (!window.grecaptcha) {
                const script = document.createElement('script');
                script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
                script.async = true;
                script.defer = true;
                document.body.appendChild(script);
            }
        };

        loadReCaptcha();
    }, [siteKey]);

    const executeRecaptcha = (action) => {
        return new Promise((resolve) => {
            if (!window.grecaptcha) {
                console.error('reCAPTCHA not loaded');
                resolve(null);
            }

            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(siteKey, { action }).then((token) => {
                    resolve(token);
                });
            });
        });
    };

    return { executeRecaptcha };
};

export default useGoogleReCaptcha;
