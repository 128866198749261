import { CLEAR_MESSAGE, NOTIFICATION_MESSAGE } from "./constants";

const initialState = {
    message: null,
};

const register = (localState = initialState, action) => {
    switch (action.type) {
        case NOTIFICATION_MESSAGE: {
            const { message } = action.payload;
            return {
                ...localState,
                message: message,
            };
        }
        case CLEAR_MESSAGE: {
            return {
                ...localState,
                message: null,
            };
        }
        default: {
            return localState;
        }
    }
};

export default register;